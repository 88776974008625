
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>This Domain is for Sale...</h1>
        <h2>Contact: <a href="mailto:naik.shashank007@gmail.com" className="App-link">naik.shashank007@gmail.com</a></h2>
      </header>
    </div>
  );
}

export default App;
